import React from 'react'
import Header from '../header/Header'
import All from '../all/All'

const Landing = () => {
  return (
    <>
<Header />
      <All/>

    </>
  )
}

export default Landing