import React from 'react'

const Under = () => {
  return (
    <div className='undercontainer'>
    <h1 className='undertitle'>Module Under Construction</h1>
    <p className='undermessage'>
    This Module is under development.
     Please check back soon
    </p>

    
<div class="spinner"></div>
    
  </div>
  )
}

export default Under